import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Card, Form, CardBody, Container, Alert, CardTitle, Input, FormGroup } from "reactstrap";
import Dropzone from "react-dropzone";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  CSV_FILE_TYPE_LISTINGS,
  BULK_LISTINGS_COLUMNS_DESCRIPTION,
  ITEM_IDENTIFIER,
  MARKET_PLACES,
  MP_ACC_STATUSES,
  HELP_DOCS_LINK,
} from "../../../constants/index";
import { toPascalCase, getUserInfo, checkPlanAvailability, RenderIf } from "../../../utils/common";
import moment from "moment-timezone";
import { omit, pick } from "lodash";
import Select from "react-select";
// actions
import { addCSVListings, selectFileError, setPreloader } from "../../../store/actions";

const CSVListings = (props) => {
  const papa = require("papaparse");
  const [usageWarning, setUsageWaring] = useState({
    confirmBox: false,
    message: "",
  });
  const [selectedFile, setselectedFile] = useState([]);
  const [fileSizeError, setFileSizeError] = useState();
  const [marketplaceAccount, setMarketplaceAccount] = useState({ value: "", label: "Select an account" });
  const [identifier, setIdentifier] = useState({ value: "", label: "Select" });
  const [itemIdentifiers, setItemIdentifiers] = useState(ITEM_IDENTIFIER);
  const [file, setFile] = useState({ value: "", label: "Select a file type" });
  const [stockLock, isStockLock] = useState(false);
  const [defaultStock, setDefaultStock] = useState(0);
  const { accounts } = useSelector((state) => state.MarketPlaceAccount);
  const { account } = useSelector((state) => state.Settings);
  const { error, success, loading } = useSelector((state) => state.Listings);
  const [processTime, setProcessTime] = useState("");
  const dispatch = useDispatch();
  const CSV_FILE_TYPES =
    accounts?.data.filter((x) => x.marketplace === "facebook").length > 0
      ? Object.assign(CSV_FILE_TYPE_LISTINGS, { facebook_bulk_listing: "facebook_bulk_listing" })
      : CSV_FILE_TYPE_LISTINGS;
  const isMatthew = getUserInfo()?.account_id === 2;

  function handleAcceptedFile (file) {
    file.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    setselectedFile(file);
  }

  function checkListingUsage (data, records) {
    if (data.plan_metadata) {
      const { currentListingCount, metadata } = data.plan_metadata;
      if (!parseInt(metadata.listings)) return;

      if (currentListingCount + records > parseInt(metadata.listings))
        setUsageWaring({
          confirmBox: true,
          message: `By adding ${records} more listings you will exceed your plan limit and your plan will be upgraded. Current usage is ${currentListingCount} / ${metadata.listings}`,
        });
    }
  }

  function handleListingSubmit () {
    let error = "";
    if (selectedFile.length <= 0) error = "Please select a file.";
    else if (file.value === "") {
      document.getElementById("file_type").style.border = "2px solid red";
      error = "Please select a file Type";
    } else if (
      [
        CSV_FILE_TYPE_LISTINGS.bulk_listing,
        CSV_FILE_TYPE_LISTINGS.suppliers_upload,
        CSV_FILE_TYPE_LISTINGS.retire_skus,
      ].includes(file.value) &&
      !marketplaceAccount.value
    )
      error = "Please select Marketplace account.";
    dispatch(selectFileError(error));

    if (error) return;

    dispatch(
      addCSVListings({
        data: {
          file_name: selectedFile[0],
          file_type: file.value,
          item_identifier: identifier.value.includes("asin") ? "item_id" : identifier.value,
          va_id: getUserInfo().id,
          ...(file.value === "bulk_listing" && isMBrannan() ? { stock_lock: stockLock, manual_stock: defaultStock } : {}),
        },
        id: marketplaceAccount.value,
      }),
    );
    setselectedFile([]);
  }

  function setIdentifiersDropDown (label) {
    if (label?.includes(MARKET_PLACES.walmart)) {
      setIdentifier({ label: toPascalCase(ITEM_IDENTIFIER.item_id), value: ITEM_IDENTIFIER.item_id });
      setItemIdentifiers(omit(ITEM_IDENTIFIER, ITEM_IDENTIFIER.asin));
    } else if (label?.includes(MARKET_PLACES.shopify)) {
      setIdentifier({ label: toPascalCase(ITEM_IDENTIFIER.item_id), value: ITEM_IDENTIFIER.item_id });
      setItemIdentifiers(pick(ITEM_IDENTIFIER, ITEM_IDENTIFIER.item_id));
    } else {
      setIdentifier({ label: toPascalCase(ITEM_IDENTIFIER.asin), value: ITEM_IDENTIFIER.asin });
      setItemIdentifiers(omit(ITEM_IDENTIFIER, ITEM_IDENTIFIER.item_id));
    }
  }

  function formatBytes (bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const isMBrannan = () => getUserInfo()?.agency_id === 3382;

  useEffect(() => {
    dispatch(setPreloader(loading));
  }, [loading]);

  useEffect(() => {
    if (accounts?.data) {
      if (accounts?.data.filter((x) => x.valid && x.enabled).length === 1) {
        const { id, marketplace, name } = accounts.data[0];
        setMarketplaceAccount({ value: id, label: [marketplace, name].join(": ") });
        setIdentifiersDropDown([marketplace, name].join(": "));
      } else setMarketplaceAccount({ value: "", label: "Select an account" });
    }
  }, [accounts]);

  useEffect(() => {
    if (selectedFile.length > 0) {
      papa.parse(selectedFile[0], {
        header: true,
        complete: function ({ data }) {
          setProcessTime(moment.duration((3600 / 7000) * (data.length || 1), "second").humanize());
          checkListingUsage(account.data, data.length);
        },
      });
    }
  }, [selectedFile]);

  useEffect(() => {
    setTimeout(() => setFileSizeError(), 3000);
  }, [fileSizeError]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={
              <>
                CSV OF LISTINGS{" "}
                {
                  <a href={HELP_DOCS_LINK} rel="noopener noreferrer" target="_blank">
                    <i className="bx bx-info-circle"></i>
                  </a>
                }
              </>
            }
          />

          <RenderIf isTrue={success}>
            <div className="auto-hide">
              <Alert color="success" className="my-1">
                <i className="bx bx-info-circle pr-2"></i>
                {success || ""}
              </Alert>
            </div>
          </RenderIf>

          <RenderIf isTrue={account?.data && !checkPlanAvailability(account?.data)}>
            <div className="auto-hide">
              <Alert color="warning" role="alert">
                {"You exceeded your current plan"}
              </Alert>
            </div>
          </RenderIf>

          <RenderIf isTrue={error}>
            <div className="auto-hide">
              <Alert color="danger">
                <i className="bx bx-info-circle pr-2"></i>
                {error || ""}
              </Alert>
            </div>
          </RenderIf>

          <RenderIf isTrue={fileSizeError}>
            <div className="auto-hide">
              <Alert color="danger">
                <i className="bx bx-info-circle pr-2"></i>
                {fileSizeError}
              </Alert>
            </div>
          </RenderIf>

          <RenderIf isTrue={file.value === "bulk_listing" && usageWarning.message}>
            <div className="auto-hide">
              <Alert color="warning">
                <i className="bx bx-info-circle pr-2"></i>
                {usageWarning.message || ""}
              </Alert>
            </div>
          </RenderIf>

          <Row>
            <Col className="col-12">
              <Card>
                <div style={{ marginLeft: 20, marginTop: 20 }} className="d-flex justify-content-between">
                  <CardTitle>Upload a CSV For Bulk Actions</CardTitle>
                  <div className="d-flex" style={{ height: "35px" }}>
                    <div className="d-flex mr-4">
                      <FormGroup className="select2-container" style={{ width: "200px" }}>
                          <Select
                            name="file_type"
                            value={file}
                            onChange={(e) => {
                              setFile(e);
                            }}
                            options={Object.entries(
                              accounts?.data.find((store) => store.id === parseInt(marketplaceAccount.value))?.marketplace ===
                            MARKET_PLACES.shopify
                                ? pick(CSV_FILE_TYPE_LISTINGS, "suppliers_upload")
                                : CSV_FILE_TYPES,
                            ).map(([fileKey, val], key) => (
                              { value: fileKey, label: fileKey.includes("oag") ? "Bulk List OA Genius" : toPascalCase(val) }
                            ))}
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>
                    </div>
                    {accounts?.data.filter((x) => x.valid && x.enabled).length > 1 && (
                      <div className="mr-3">
                        <FormGroup className="select2-container" style={{ width: "200px" }}>
                          <Select
                            name="marketplace_id"
                            value={marketplaceAccount}
                            onChange={(e) => {
                              setMarketplaceAccount(e);
                              setIdentifiersDropDown(e.label);
                            }}
                            options={accounts?.data
                              ?.filter(
                                (x) =>
                                  x.valid === true &&
                                x.enabled === true &&
                                x.status === MP_ACC_STATUSES.active,
                              )
                              ?.map((acc, key) => (
                                { value: acc.id, label: [acc.marketplace, acc.name].join(": ") }
                              ))}
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>
                      </div>
                    )}
                    {file.value === "suppliers_upload" && (
                      <div className="mr-3">
                        <FormGroup className="select2-container" style={{ width: "100px" }}>
                          <Select
                            name="item_identifier"
                            isDisabled={!marketplaceAccount.value}
                            value={identifier}
                            onChange={(e) => {
                              setIdentifier(e);
                            }}
                            options={Object.keys(itemIdentifiers)?.map((identifier) => (
                              { value: identifier, label: toPascalCase(identifier) }
                            ))}
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>
                      </div>
                    )}
                    {file.value === "bulk_listing" && isMBrannan() && (
                      <div className="custom-control custom-checkbox mt-2 mr-3">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="is_custom"
                          onChange={() => false}
                          checked={stockLock}
                        />
                        <label
                          className="custom-control-label"
                          onClick={() => {
                            isStockLock(!stockLock);
                          }}
                        >
                          Stock Lock
                        </label>
                      </div>
                    )}
                    {stockLock && (
                      <div className="miw-160 mr-3">
                        <Input
                          name="default_stock"
                          type="number"
                          min={0}
                          value={defaultStock || "Default Stock Lock"}
                          placeholder="Default Stock Lock"
                          onChange={(e) => setDefaultStock(e.target.value || 0)}
                          onBlur={() => {
                            if (defaultStock < 0) setDefaultStock(0);
                          }}
                        />
                      </div>
                    )}
                    {file.value && (
                      <div className="d-flex">
                        <a
                          className="mr-3 btn btn-primary waves-effect waves-light"
                          href={`/template_file_for_${
                            isMatthew && file.value === "suppliers_upload" ? `${file.value}_matt` : file.value
                          }.csv`}
                          download
                        >
                          Download Sample File
                        </a>
                      </div>
                    )}
                  </div>
                </div>
                <CardBody>
                  <Form>
                    <Dropzone
                      onDropRejected={(d) => setFileSizeError("File size must be less than 15MB.")}
                      maxSize={15728640}
                      onDrop={(acceptedFile) => {
                        handleAcceptedFile(acceptedFile);
                      }}
                      accept=".csv"
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div className="dz-message needsclick mt-2" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                            </div>
                            <h4>Drag or Click to Upload CSV File</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFile.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col>
                                  {f.name}
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                  <p className="mb-0">
                                    <strong>{processTime ? `Will take ${processTime} to process` : ""}</strong>
                                  </p>
                                </Col>
                                <i
                                  title="Remove File"
                                  className="bx bx-sm mdi mdi-close pr-4 text-danger cursor-pointer"
                                  onClick={() => setselectedFile([])}
                                />
                              </Row>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                  </Form>
                  {account?.data && (
                    <div className="text-center mt-4">
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={handleListingSubmit}
                        disabled={!checkPlanAvailability(account?.data)}
                      >
                        Send File
                      </button>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col lg={12}>
              <div className="d-flex flex-column">
                <h5>For More Information about Bulk Listings Columns see here: (* required)</h5>
                <ul>
                  {BULK_LISTINGS_COLUMNS_DESCRIPTION.map((x, key) => (
                    <li key={"_sub_title_" + key} className="m-2">
                      <h6 style={{ display: "inline" }}>
                        <u>{x?.subHeading}</u>
                      </h6>
                      {" - "}
                      {x.content}
                    </li>
                  ))}
                </ul>
                <a
                  className="text-center mb-4"
                  href="https://docs.google.com/document/d/15AkQBa_4JCa1gVSlhpwAhpO1C_W9P6lSq02kJo-kTQU/edit?tab=t.0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click for more details
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CSVListings;
