import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Utiles
import {
  verifyRole,
  getUserInfo,
  isAODev,
  isWHStaff,
  RenderIf,
  isShowWHPages,
  isUserHaveFullAccess,
  addStoreFront,
  isAdmin,
  hasFullAccessOfAnyPlan,
} from "../../utils/common";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter, Link } from "react-router-dom";
// i18n
import { withNamespaces } from "react-i18next";

import {
  ACCOUNT_STATUSES,
  NEW_DASHBOARD_URL,
  RETURN_LABEL_USERS,
  JOB_DASHBOARD_USERS,
  PROFIT_ANALYZER_URL,
} from "../../constants";
import { Badge } from "reactstrap";
const isSourceOrderPage = (path) => /\/source_orders\//.test(path);

const SidebarContent = (props) => {
  const dispatch = useDispatch();
  const { account: tenantObj } = useSelector((state) => state.Settings);
  const { accounts: mpAccounts } = useSelector((state) => state.MarketPlaceAccount);
  const { associatedWarehouses } = useSelector((state) => state.WareHouses);
  const isShowReturn = isShowWHPages(mpAccounts?.data, "return_wh_id");
  const isTwoStep = tenantObj?.data?.two_step_feature;
  const isStoreAdded = !!tenantObj?.data?.stores_count;
  const isWarehouseAssociated = !!associatedWarehouses?.data?.length;

  const isShowWHTabs = isTwoStep || isShowReturn;

  const isAllowed = verifyRole("admin", "owner");
  const isOwner = verifyRole("owner");
  const isAdminUser = isAdmin();
  const account = getUserInfo();

  const isPaWhStaff = account?.isWH && account?.warehouse_id === 1;
  const isPartialAgency = !account?.agency_id;
  const isAgency = tenantObj?.data?.is_agency && tenantObj?.data?.stores_count === 0;
  const isFullAccess = isUserHaveFullAccess();
  const isFullAccessOfAnyPlan = hasFullAccessOfAnyPlan();
  const isShowWHRelatedTabs = isAdminUser || isWarehouseAssociated || isUserHaveFullAccess();
  const isRepricerFullAccess = isUserHaveFullAccess("repricer");

  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      // eslint-disable-next-line no-new
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname || (isSourceOrderPage(pathName) && isSourceOrderPage(items[i].pathname))) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, []);

  useEffect(() => {}, [!!associatedWarehouses?.data]);

  const isDefaultTenant = account?.account_id === 2;

  function activateParentDropdown (item) {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); // a

          const parent4 = parent3.parentElement;

          if (parent4) {
            parent4.classList.add("mm-active");
            parent4.classList.add("mm-show");

            const parent5 = parent4.parentElement;

            if (parent5) {
              parent5.childNodes[0].classList.add("mm-active"); // a
            }
          }
        }
      }
      return false;
    }
    return false;
  }
  const onlyAdmins = !isWHStaff() && verifyRole("admin");

  const fbBtnStyle =
    localStorage.getItem("leftSideBarType") === "condensed"
      ? { size: "small", layout: "box_count", class: "pl-1" }
      : { size: "large", layout: "button_count", class: "pl-4" };

  const toggleModal = () => {
    props.resetState();
    props.toggleReportModal(true);
  };

  const renderBadge = () => (
    <Badge
      className="font-size-8 badge-primary text-white ml-1"
      style={{ margin: 0 }}
      color="primary"
      pill
      children="New"
    />
  );

  const getRepricerTabText = () => {
    if (isFullAccess) return "Repricer";
    if (isRepricerFullAccess) return "Repricer & Listings";
    return "Listings";
  };

  return (
    <React.Fragment>
      <div id="sidebar-menu" className="position-sticky" style={{ top: "5em" }}>
        <ul className="metismenu list-unstyled" id="side-menu">
          {!isWHStaff() && (
            <>
              {isPartialAgency && (
                <>
                  <li>
                    <Link to="/agency" className="sub-menu">
                      <i className="mdi mdi-graph"></i>
                      <span>{props.t("Agency Dashboard")}</span>
                    </Link>
                  </li>
                  {isAgency && isAllowed && (
                    <li>
                      <Link to="/users" className="sub-menu">
                        <i className="bx bxs-user-detail"></i>
                        <span>{props.t("Users")}</span>
                      </Link>
                    </li>
                  )}
                </>
              )}

              {!isAgency && (
                <>
                  <RenderIf isTrue={tenantObj?.data && !isStoreAdded}>
                    <li>
                      <a
                        style={{ padding: "0.625rem 1.5rem", transition: "all 0.4s", display: "block" }}
                        className="sub-menu d-flex align-items-center font-size-13 cursor-pointer"
                        onClick={() => addStoreFront(dispatch)}
                      >
                        <i className="bx bx-sm bx-error-circle text-danger" style={{ minWidth: "2rem" }}></i>
                        <span>
                          <u className="text-danger">{props.t("Connect Your Storefront")}</u>
                        </span>
                      </a>
                    </li>
                  </RenderIf>
                  <RenderIf isTrue={!isFullAccess || isAdminUser}>
                    <li>
                      <Link to={NEW_DASHBOARD_URL} className="sub-menu">
                        <i className="bx bx-stats"></i>
                        <span>{props.t("New Dashboard")}</span>
                      </Link>
                    </li>
                    <RenderIf isTrue={isAdminUser}>
                      <li>
                        <Link to={PROFIT_ANALYZER_URL} className="sub-menu">
                          <i className="bx bx-bar-chart"></i>
                          <span>{props.t("Profit Analyzer")}</span>
                        </Link>
                      </li>
                    </RenderIf>
                  </RenderIf>
                  <RenderIf isTrue={isFullAccess}>
                    <li>
                      <Link to="/dashboard" className="sub-menu">
                        <i className="bx bx-line-chart-down"></i>
                        <span>{props.t("Dashboard")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/listings" className="sub-menu">
                        <i className="bx bx-list-ol"></i>
                        <span>{props.t("Listings")}</span>
                      </Link>
                    </li>
                  </RenderIf>
                  <RenderIf isTrue={verifyRole("admin", "owner", "manager", "va") && isStoreAdded}>
                    <li>
                      <Link
                        to={isUserHaveFullAccess("extension") ? "/#" : "/listings_new"}
                        className={`${isUserHaveFullAccess("extension") ? "has-arrow" : ""} waves-effect`}
                      >
                        <i className="bx bx-dollar-circle" />
                        <span>{props.t(getRepricerTabText())}</span>
                      </Link>
                      <RenderIf
                        isTrue={isRepricerFullAccess}
                        fallback={
                          <RenderIf isTrue={isFullAccessOfAnyPlan}>
                            <ul className="sub-menu" aria-expanded="false">
                              <li>
                                <Link to="/listings_new" className="sub-menu">
                                  <i className="bx bx-list-ul" />
                                  <span>{props.t("View Listings")}</span>
                                </Link>
                              </li>
                              <RenderIf isTrue={isUserHaveFullAccess("extension")}>
                                <li>
                                  <Link to="/upload_listings_new">
                                    <i className="bx bx-upload" />
                                    <span>{props.t("Imports")}</span>
                                  </Link>
                                </li>
                              </RenderIf>
                              <li>
                                <Link to="/tags">
                                  <i className="bx bx-tag" />
                                  <span>{props.t("Tags")}</span>
                                </Link>
                              </li>
                            </ul>
                          </RenderIf>
                        }
                      >
                        <ul className="sub-menu" aria-expanded="false">
                          <li>
                            <Link to="/listings_new" className="sub-menu">
                              <i className="bx bx-list-ul"></i>
                              <span>{props.t("Listings")}</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/strategies" className="sub-menu">
                              <i className="bx bx-task" />
                              <span>{props.t("Strategies")}</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/formulas">
                              <i className="bx bx-notepad" />
                              <span>{props.t("Formulas")}</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/upload_listings_new">
                              <i className="bx bx-upload" />
                              <span>{props.t("Imports")}</span>
                            </Link>
                          </li>
                          <RenderIf isTrue={isAdminUser}>
                            <li>
                              <Link to="/tags">
                                <i className="bx bx-tag" />
                                <span>{props.t("Tags")}</span>
                              </Link>
                            </li>
                          </RenderIf>
                          <RenderIf isTrue={isFullAccess}>
                            <li>
                              <Link to="/repricer_settings">
                                <i className="bx bx-cog"></i>
                                <span>{props.t("Repricer Settings")}</span>
                              </Link>
                            </li>
                          </RenderIf>
                        </ul>
                      </RenderIf>
                    </li>
                  </RenderIf>
                  <RenderIf
                    isTrue={isFullAccess}
                    fallback={
                      <RenderIf isTrue={isStoreAdded && isFullAccessOfAnyPlan}>
                        <li>
                          <Link to="/source_orders/new">
                            <i className="bx bxs-shopping-bags" />
                            <span>{props.t("Orders")}</span>
                          </Link>
                        </li>
                      </RenderIf>
                    }
                  >
                    <li>
                      <Link to="/#" className="has-arrow waves-effect">
                        <i className="bx bxs-shopping-bags"></i>
                        <span>{props.t("Orders")}</span>
                      </Link>
                      <ul className="sub-menu" aria-expanded="false">
                        <RenderIf isTrue={isStoreAdded}>
                          <li>
                            <Link to="/source_orders/new" className="sub-menu">
                              <i className="bx bx-cart-alt"></i>
                              <span>{props.t("View Orders")}</span>
                            </Link>
                          </li>
                        </RenderIf>
                        <li>
                          <Link to="/credentials" className="sub-menu">
                            <i className="bx bx-key"></i>
                            <span>{props.t("Buying Accounts")}</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/credit_cards" className="sub-menu">
                            <i className="bx bx-credit-card-alt"></i>
                            <span>{props.t("Credit Cards")}</span>
                          </Link>
                        </li>
                        {RETURN_LABEL_USERS.includes(getUserInfo()?.id) ? (
                          <li>
                            <Link to="/return_labels" className="sub-menu">
                              <i className="fa fa-lg fa-undo-alt action-icon pr-2 font-size-15"></i>
                              <span>{props.t("Return Labels")}</span>
                            </Link>
                          </li>
                        ) : null}
                      </ul>
                    </li>
                    <li>
                      <Link to="/#" className="has-arrow waves-effect">
                        <i className="bx bx-dots-horizontal-rounded"></i>
                        <span>{props.t("Other")}</span>
                      </Link>
                      <ul className="sub-menu" aria-expanded="false">
                        <li>
                          <Link to="/uploaded_files" className="sub-menu">
                            <i className="bx bx-file"></i>
                            <span>{props.t("Files")}</span>
                          </Link>
                        </li>
                        <RenderIf isTrue={tenantObj?.data?.status === ACCOUNT_STATUSES.active}>
                          <li>
                            <Link to="/emails" className="sub-menu">
                              <i className="bx bx-envelope"></i>
                              <span>{props.t("Emails")}</span>
                            </Link>
                          </li>
                        </RenderIf>
                        {isOwner && (
                          <li>
                            <Link to="/users" className="sub-menu">
                              <i className="bx bxs-user-detail"></i>
                              <span>{props.t("Users")}</span>
                            </Link>
                          </li>
                        )}
                        {isDefaultTenant && (
                          <>
                            {getUserInfo()?.role === "admin" ? (
                              <li>
                                <Link to="/proxy_users" className="sub-menu">
                                  <i className="bx bxs-user-voice"></i>
                                  <span>{props.t("Proxy Users")}</span>
                                </Link>
                              </li>
                            ) : null}
                            <li>
                              <Link to="/amazon_sellers" className="sub-menu">
                                <i className="bx bxl-amazon"></i>
                                <span>{props.t("Sellers")}</span>
                              </Link>
                            </li>
                          </>
                        )}
                        <li>
                          <Link to="/shipment-report" className="sub-menu">
                            <i className="bx bxs-file"></i>
                            <span>{props.t("Shipment Report")}</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/credit_logs" className="sub-menu">
                            <i className="bx bxs-report"></i>
                            <span>{props.t("Credit Log")}</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/feeds" className="sub-menu">
                            <i className="bx bx-news"></i>
                            <span>{props.t("Feeds")}</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/shipping_templates" className="sub-menu">
                            <i className="bx bxs-truck"></i>
                            <span>{props.t("Shipping Templates")}</span>
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </RenderIf>
                </>
              )}
              <RenderIf isTrue={isFullAccess}>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="bx bx-no-entry"></i>
                    <span>{props.t("Restrictions")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/restricted_brands" className="sub-menu">
                        <i className="bx bx-notification-off"></i>
                        <span>{props.t("Restricted Brands")}</span>
                      </Link>
                    </li>
                    {isAgency && [3382, 4251].includes(tenantObj?.data?.id) && (
                      <li>
                        <Link to="/restricted_items" className="sub-menu">
                          <i className="mdi mdi-card-bulleted-off-outline"></i>
                          <span>{props.t("Restricted Items")}</span>
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </RenderIf>
              {!isAgency && (
                <>
                  <RenderIf isTrue={isFullAccess}>
                    {verifyRole("admin", "owner", "manager") && (
                      <li>
                        <Link to="/suppliers">
                          <i className="bx bx-store"></i>
                          <span>{props.t("Suppliers")}</span>
                        </Link>
                      </li>
                    )}
                  </RenderIf>
                  <RenderIf isTrue={isFullAccess}>
                    <li>
                      <Link to="/resources">
                        <i className="mdi mdi-google-circles-communities"></i>
                        <span>{props.t("Resources")}</span>
                      </Link>
                    </li>
                  </RenderIf>
                </>
              )}
            </>
          )}
          <RenderIf
            isTrue={!isAgency && ((verifyRole("manager", "va") && isShowWHTabs) || isAllowed || isDefaultTenant)}
          >
            <li>
              <Link
                to={isShowWHRelatedTabs ? "/#" : "/warehouse"}
                className={`${isShowWHRelatedTabs ? "has-arrow" : ""} waves-effect`}
              >
                <i className="mdi mdi-home-city-outline"></i>
                <span>{props.t("Warehouse")}</span>
              </Link>
              <RenderIf isTrue={isShowWHRelatedTabs}>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/inventory-items">
                      <i className="bx bx-sm bx-store float-left"></i>
                      <span className="d-flex align-items-center">
                        {props.t("Inventory Items")} {renderBadge()}
                      </span>
                    </Link>
                  </li>
                  <RenderIf isTrue={isAllowed}>
                    <li>
                      <Link to="/warehouse">
                        <i className="mdi mdi-archive-outline"></i>
                        <span>{props.t("View Warehouses")}</span>
                      </Link>
                    </li>
                  </RenderIf>
                  <RenderIf isTrue={verifyRole("admin")}>
                    <li>
                      <Link to="/label_generation_stats">
                        <i className="bx bx-file"></i>
                        <span>{props.t("Label Statistics")}</span>
                      </Link>
                    </li>
                  </RenderIf>
                  <RenderIf isTrue={isShowWHTabs}>
                    <RenderIf isTrue={verifyRole("admin", "owner", "manager") || isWHStaff()}>
                      <li>
                        <Link to="/warehouse_charges">
                          <i className="bx bx-sm bx-dollar"></i>
                          <span>{props.t("Warehouse Charges")}</span>
                        </Link>
                      </li>
                    </RenderIf>
                    <RenderIf isTrue={isFullAccess}>
                      <li>
                        <Link to="/purchase_order">
                          <i className="bx bx-package"></i>
                          <span>{props.t("Add Purchase Order")}</span>
                        </Link>
                      </li>
                    </RenderIf>
                  </RenderIf>
                  <RenderIf isTrue={isFullAccess}>
                    <RenderIf isTrue={verifyRole("admin")}>
                      <li>
                        <Link to="/scanforms">
                          <i className="bx bx-file"></i>
                          <span>{props.t("Generate Scanforms")}</span>
                        </Link>
                      </li>
                    </RenderIf>
                  </RenderIf>
                  <RenderIf isTrue={isShowReturn}>
                    <li>
                      <Link to="/returns">
                        <i className="bx mdi mdi-keyboard-return"></i>
                        <span>{props.t("Returns")}</span>
                      </Link>
                    </li>
                  </RenderIf>
                  <RenderIf isTrue={isPaWhStaff}>
                    <hr style={{ marginBottom: 10, marginTop: 10, marginLeft: "3.6rem" }} />
                    <li>
                      <Link to="/warehouse_items">
                        <i className="bx bx-sm bx-slider"></i>
                        <span>{props.t("Warehouse Items")}</span>
                      </Link>
                    </li>
                    <RenderIf isTrue={isShowWHTabs}>
                      <li>
                        <Link to="/warehouse_storage">
                          <i className="bx bx-sm bx-store"></i>
                          <span>{props.t("Inventory")}</span>
                        </Link>
                      </li>
                    </RenderIf>
                  </RenderIf>
                </ul>
              </RenderIf>
            </li>
          </RenderIf>
          <RenderIf isTrue={!isAgency}>
            {(isAllowed || getUserInfo()?.id === 5335) && ( // access allowed to Al Sadani
              <li>
                <Link to="/settings">
                  <i className="bx bx-cog"></i>
                  <span>{props.t("Settings and Billings")}</span>
                </Link>
              </li>
            )}
          </RenderIf>

          <RenderIf isTrue={isWHStaff()}>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-home-city-outline"></i>
                <span>{props.t("Warehouse")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/warehouse_dashboard">
                    <i className="mdi mdi-archive-outline"></i>
                    <span>{props.t("Dashboard & Search")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/inbound_items">
                    <i className="bx bx-archive-in float-left"></i>
                    <span className="d-flex align-items-center">
                      {props.t("Inbound Items")} {renderBadge()}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/inventory-items">
                    <i className="bx bx-sm bx-store float-left"></i>
                    <span className="d-flex align-items-center">
                      {props.t("Inventory Items")} {renderBadge()}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/outbound-items">
                    <i className="bx bx-archive-out float-left"></i>
                    <span className="d-flex align-items-center">
                      {props.t("Outbound Items")} {renderBadge()}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/warehouse_charges">
                    <i className="bx bx-sm bx-dollar"></i>
                    <span>{props.t("Warehouse Charges")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/scanforms">
                    <i className="bx bx-file"></i>
                    <span>{props.t("Generate Scanforms")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/returns">
                    <i className="bx mdi mdi-keyboard-return"></i>
                    <span>{props.t("Returns")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/label_generation_stats">
                    <i className="bx bx-file"></i>
                    <span>{props.t("Label Statistics")}</span>
                  </Link>
                </li>
                <RenderIf isTrue={isPaWhStaff}>
                  <hr style={{ marginBottom: 10, marginTop: 10, marginLeft: "3.6rem" }} />
                  <li>
                    <Link to="/warehouse_items">
                      <i className="bx bx-sm bx-slider"></i>
                      <span>{props.t("Warehouse Items")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/warehouse_storage">
                      <i className="bx bx-sm bx-store"></i>
                      <span>{props.t("Inventory")}</span>
                    </Link>
                  </li>
                </RenderIf>
              </ul>
            </li>
          </RenderIf>

          {!isWHStaff() && (isAODev() || getUserInfo()?.id === 17) ? (
            <li>
              <Link to="/ao_ecs_tasks">
                <i className="fas fa-robot"></i>
                <span>{props.t("AO ECS Tasks")}</span>
              </Link>
            </li>
          ) : null}

          <RenderIf isTrue={isStoreAdded && (isFullAccess || isFullAccessOfAnyPlan)}>
            <RenderIf isTrue={!isAgency && !isWHStaff()}>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bxs-file"></i>
                  <span>{props.t("Reports")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/exported_reports" className="sub-menu">
                      <span>{props.t("Exported Reports")}</span>
                    </Link>
                  </li>
                  <RenderIf isTrue={isFullAccess && isAllowed}>
                    <li>
                      <Link to="/least_selling_items" className="sub-menu">
                        <span>{props.t("Sales Report")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/view_report" className="sub-menu">
                        <span>{props.t("VA Performance Report")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/order_processor_report" className="sub-menu">
                        <span>{props.t("Order Processor Report")}</span>
                      </Link>
                    </li>
                  </RenderIf>
                  <li>
                    <div onClick={() => toggleModal()} className="sub-menu modal_route">
                      <span>Generate Report</span>
                    </div>
                  </li>
                </ul>
              </li>
            </RenderIf>
          </RenderIf>
          <RenderIf isTrue={onlyAdmins}>
            <hr />
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bx-crown"></i>
                <span>{props.t("Super Admin")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                {!isAgency && (
                  <li>
                    <Link to="/users" className="sub-menu">
                      <i className="bx bxs-user-detail"></i>
                      <span>{props.t("Users")}</span>
                    </Link>
                  </li>
                )}
                <li>
                  <Link to="/custom_plans" className="sub-menu">
                    <i className="bx bx-dollar-circle"></i>
                    <span>{props.t("Custom Plans")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/tenant_accounts">
                    <i className="bx bx-group"></i>
                    <span>{props.t("All Tenants")}</span>
                  </Link>
                </li>
                <RenderIf isTrue={!isAgency}>
                  <li>
                    <Link to="/platforms" className="sub-menu">
                      <i className="bx bx-store-alt"></i>
                      <span>{props.t("Platforms")}</span>
                    </Link>
                  </li>
                </RenderIf>
                <li>
                  <Link to="/matches_requests" className="sub-menu">
                    <i className="bx bx-receipt"></i>
                    <span>{props.t("Matches Requests")}</span>
                  </Link>
                </li>
                <RenderIf isTrue={JOB_DASHBOARD_USERS.includes(getUserInfo()?.id)}>
                  <li>
                    <Link to="/jobs_summary" className="sub-menu">
                      <i className="bx bx-receipt"></i>
                      <span>{props.t("Jobs Summary")}</span>
                    </Link>
                  </li>
                </RenderIf>
                <li>
                  <Link to="/search_orders" className="sub-menu">
                    <i className="bx bx-search"></i>
                    <span>{props.t("Search Orders")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/proxy_manager" className="sub-menu">
                    <i className="bx bx-data"></i>
                    <span>{props.t("Proxy Manager")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/marketpalce_scratchpad" className="sub-menu">
                    <i className="bx bxs-notepad"></i>
                    <span>{props.t("Scratchpad")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/check_offers" className="sub-menu">
                    <i className="bx bxs-offer"></i>
                    <span>{props.t("Check Offers")}</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bx-stats"></i>
                <span>{props.t("Statistics")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/leaderboard" className="sub-menu">
                    <i className="bx bx-buildings"></i>
                    <span>{props.t("Leader Board")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/fifty_dashboard" className="sub-menu">
                    <i className="bx bx-file"></i>
                    <span>{props.t("50/50 Dashboard")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/admin_dashboard" className="sub-menu">
                    <i className="bx bx-home-circle"></i>
                    <span>{props.t("Admin Dashboard")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/ao_stats">
                    <i className="bx bx-bot"></i>
                    <span>{props.t("AO Stats")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/ao_tasks">
                    <i className="bx bx-task"></i>
                    <span>{props.t("AO Tasks")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/refreshers" className="sub-menu">
                    <i className="bx bx-refresh"></i>
                    <span>{props.t("Refreshers")}</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bx-history"></i>
                <span>{props.t("History & Logs")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/free_credits_history">
                    <i className="bx bx-wallet"></i>
                    <span>{props.t("Free Credits History")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/activity_logs" className="sub-menu">
                    <i className="bx bx-notepad"></i>
                    <span>{props.t("Activity Logs")}</span>
                  </Link>
                </li>
              </ul>
            </li>
            <RenderIf isTrue={isAdminUser}>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-search"></i>
                  <span>{props.t("Insights")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/warehouse_insights" className="sub-menu">
                      <i className="mdi mdi-warehouse"></i>
                      <span>{props.t("Warehouse Insights")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
            </RenderIf>
          </RenderIf>
          <hr />
          {!isWHStaff() && (
            <li>
              <Link to={{ pathname: "https://www.facebook.com/groups/ecomcircles" }} target="_blank">
                <i className="bx bxl-facebook-circle"></i>
                <span>Facebook</span>
              </Link>
            </li>
          )}
          {!isWHStaff() && isDefaultTenant ? (
            <li className={`${fbBtnStyle.class || ""} mt-2`}>
              <div
                className="fb-like"
                data-href="https://developers.facebook.com/docs/plugins/"
                data-width=""
                data-layout={fbBtnStyle.layout}
                data-action="like"
                data-size={fbBtnStyle.size}
                data-share="true"
                data-colorscheme={localStorage.getItem("THEME") === "light" ? "light" : "dark"}
              ></div>
            </li>
          ) : null}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default withRouter(withNamespaces()(SidebarContent));
